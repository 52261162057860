html {
  background-color: #F1F1F1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url("./fonts/avenir-medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"),
    url("./fonts/avenir-black.ttf") format("truetype");
  font-weight: normal;
}

.App {
  background-color: #F1F1F1;
}

.medium {
  font-family: Avenir !important;
}

.black {
  font-family: AvenirBlack !important;
}

.main {
  height: 820px;
  width: 100%;
  background-color: #09345B;
  /* position: relative; */
}

.container-main {
  color: #FFFFFF;
  margin: 0;
  position: relative;
  top: 5%;
  -ms-transform: translateY(-5%);
  transform: translateY(-5%);
  text-align: center;
  width: 100%;
}

.main-title {
  width: 100%;
}

.main-logo img {
  width: 280px;
  height: auto;
}

.form {
  margin-top: 50px;
  position: absolute;
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: transparent;
}

.form-button {
  text-align: right;
}

.form-button button {
  height: 40px;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  background-color: #09345B;
  margin-top: 10px;
  width: 120px;
}

.slider {
  max-width: 380px;
  min-width: 260px;
  margin: auto;
}

.form-title {
  width: 100%;
  height: auto;
  color: #000000;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
  background-color: #F1F1F1;
}

.form-group {
  margin-top: 10px;
}

.invest-button {
  background-color: #09345B;
  color: #FFFFFF;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main {
    height: 820px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .main {
    height: 800px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .main {
    height: 760px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main {
    height: 740px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .main {
    height: 740px;
  }
}